
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.thank-you {
  &__loading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-description {
      transform: translateY(50px);
    }
  }
}
