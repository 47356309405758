
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request-form {
  &--align-end {
    align-items: flex-end;
  }

  &__welcome {
    text-align: center;
    margin-bottom: 40px;
  }

  &__other-education {
    margin-top: -36px; // overrides default margins of rows from checkboxes groups and single checkboxes
  }

  &__nested-checkboxes {
    padding-left: 32px;
  }

  &__professional-education-group {
    flex-direction: column;

    & > div {
      margin: 0;
    }
  }

  &__double-hint {
    padding-top: $ui-default-measure2x;
  }

  &__hint {
    &--special-top {
      top: 96px;
    }

    &--no-top {
      top: -10px;
    }
  }

  &__degrees-group {
    flex-direction: column;
  }

  &__custom-error {
    div {
      position: relative !important;
    }
  }

  &__step {
    .ant-upload.ant-upload-drag {
      max-width: 135px;
    }
  }
}
