
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.intro {
  position: relative;
  top: 20%;
  max-width: 410px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--renewal {
    .intro__subheading {
      &.copy {
        max-width: 410px;
        margin: 5px 0 20px;
      }
    }
  }

  &__subheading {
    &.copy {
      margin: 5px auto 20px auto;
      max-width: 264px;
      text-align: center;
    }
  }

  &__button {
    min-width: 230px;
    margin-bottom: 15px;
  }
}
