
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.dashboard-view {
  text-align: center;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
