
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request-details {
  position: relative;
  max-width: 680px;

  &__hidden-btn {
    display: none;
  }

  &--column {
    flex-direction: column;
  }

  .base-summary__section-heading {
    padding: 0 90px;
  }

  &__professional-knowledge {
    &-group--nested {
      padding-left: 36px;

      ul,
      > span {
        display: block;
        padding-bottom: $ui-default-measure2x;
      }
    }
  }

  &__checkbox {
    &--disabled {
      padding-bottom: $ui-default-measure2x;
      padding-top: $ui-default-measure2x;

      span {
        color: #000;
      }

      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: $color-white;
      }
    }
  }

  &__edit {
    position: absolute;
    top: 0;
    right: 0;
    z-index: getIndex(detailsEditButton);
  }

  &__fees {
    color: $color-text;
    font-size: 14px !important;
    line-height: 26px !important;
  }

  &__additional-recipients {
    h4 {
      margin-bottom: $ui-default-measure;
    }

    input {
      width: 100%;
      max-width: initial;
    }
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  .hints-list {
    padding-left: $ui-default-measure3x;
    padding-bottom: $ui-default-measure2x;
    li {
      padding-bottom: $ui-default-measure;
      list-style: decimal;
    }
  }

  .change-email-form,
  .change-recipients-form {
    input {
      max-width: initial;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
    }
  }

  .additional-recipients-change-button {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    &--payments {
      margin-top: 30px;
    }
  }

  &__container {
    max-width: 205px;
  }

  .base-hint--for-full-width-form-box {
    left: calc(100% + 45px);
  }

  .heading {
    display: flex;

    &__value,
    &__description {
      width: 50%;
    }

    &__value {
      font-weight: 400;
    }

    .base-summary__section.history {
      padding-right: 0;
    }
  }
}
