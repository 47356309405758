
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

  .additional-fee-checkbox {
    padding: 16px;
    margin-left: 36px;
  }
  .radio-group {
    display: flex;
    margin-top: 16px;
    .radio-text {
      margin:0;
    }
  }
  .confirmations {
    .ant-checkbox-wrapper {
      padding: 8px 16px;
      border: 1px solid transparent;
    }
    .has-error .ant-checkbox-wrapper {
      border:1px solid $color-rejected;
      margin-bottom: 10px;
    }
    .fee-link {
      text-decoration: underline;
    }
  }


