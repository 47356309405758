
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.advisor-header {
  display: flex;
  justify-content: center;
  height: $header-height;

  &__title {
    background-color: $color-primary;
    color: $color-white;
    text-transform: uppercase;
    padding: 12px $ui-default-measure2x;
    height: 42px;
  }

  &--admin {
    align-items: center;
  }
}
