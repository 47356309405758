
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.history {
  margin-top: 30px;

  table {
    width: 116%;
    border-collapse: collapse;
    font-size: 14px;
  }

  &__row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-text-light;
    height: 50px;
    padding: 0 16px;

    &:nth-child(odd) {
      background-color: rgba(216, 216, 216, 0.08);
    }

    &:first-child {
      background-color: transparent;
    }

    .history__cell--values {
      &:last-child {
        div {
          display: flex;
          justify-content: center;
          border-radius: 20px;
          max-width: 96px;
          text-transform: capitalize;
          font-size: 12px;
          line-height: 26px;
          color: $color-white;
          background-color: $color-review;
        }
      }
    }

    &--approved {
      .history__cell--values:last-child {
        div {
          background-color: $color-success;
        }
      }
    }

    &--rejected {
      .history__cell--values:last-child {
        div {
          background-color: $color-rejected;
        }
      }
    }
  }

  &__cell--title,
  &__cell--values {
    width: 25%;

    div {
      display: flex;
    }
  }

  &__cell--title {
    color: $color-text;
  }

  &__cell--values {
    color: $color-text-dark;
  }
}
