
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.fields-of-activity {
  .base-summary__section {
    margin-bottom: 40px;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0;

    &--active {
      .fields-of-activity {
        &__point {
          opacity: 1;
          background-color: $color-primary;
        }

        &__check {
          display: block;
          transform: rotate(45deg);
          height: 12px;
          width: 6px;
          margin-bottom: 4px;
          border-bottom: 1px solid $color-white;
          border-right: 1px solid $color-white;
        }

        &__value {
          opacity: 1;
        }
      }
    }
  }

  &__point {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    margin-right: 20px;
    opacity: 0.6;
    background-color: $color-text;
  }

  &__value {
    opacity: 0.3;
  }
}

.academic-degrees,
.code-of-conduct {
  margin-bottom: 20px;

  &__container {
    margin: 20px 0;
  }

  .base-summary {
    margin-bottom: 40px;

    &__section {
      margin-bottom: 40px;
    }

    &__item {
      margin: 20px 0;

      &-title {
        color: $color-text-dark;
      }
    }
  }
}

.code-of-conduct {
  margin-bottom: 40px;
}

.fields-of-activity-list {
  &__items {
    margin-bottom: 20px;
  }
}
