
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.public-registry {
  &__heading {
    margin-top: 50px;
    text-transform: uppercase;
    text-align: center;
  }

  &__description {
    text-align: center;
    padding-bottom: 40px;
  }

  &__search-heading {
    margin-top: 0;
  }

  &__search--with-hint {
    position: relative;
  }

  &__education {
    .mb-def {
      margin-bottom: $ui-default-measure;
    }
    .mb-def2x {
      margin-bottom: $ui-default-measure2x;
    }

    .pl-def2x {
      padding-left:$ui-default-measure2x;
    }
  }
}
